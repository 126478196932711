import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { RootState } from '../store/store';
import { GET_USER } from '../store/user/user.graphql.ts';
import i18n from '../translations/i18n.ts';

function useAuth() {
  const { isLoading, accessToken, refreshToken, role, userId } = useSelector(
    (state: RootState) => state.auth
  );

  // Verify actual token validity
  const { data, loading } = useQuery(GET_USER, {
    variables: {
      id: userId,
    },
    onCompleted(data) {
      // Set language based on user's language
      i18n.changeLanguage(data?.user?.language?.toLowerCase() || 'fr').catch();
    },
  });

  const isAuthenticated = useMemo(() => {
    if (loading && !!accessToken) {
      return true;
    }
    return !!accessToken && !!data?.user?.id;
  }, [data, loading, accessToken]);

  return { isLoading, isAuthenticated, accessToken, refreshToken, role };
}

export default useAuth;
